import {
  Box,
  Flex,
  Container,
  Link,
  Icon,
  Stack,
  Image,
  Text,
  useColorMode,
} from '@chakra-ui/react';

import { FaTwitter, FaFacebook } from 'react-icons/fa';

import DarkModeLogo from '../assets/logodarkmode.svg'
import LightModeLogo from '../assets/logolightmode.svg'

const Footer = () => {
  const { colorMode } = useColorMode()

  const logo = { light: LightModeLogo, dark: DarkModeLogo }
  const textBgColor = { light: 'brand.400', dark: 'brand.200'}

  return (
    <Container maxW={'5xl'} py={12}>
      <Flex >
	<Stack w="100%">
	  <Box w="60%" mx="auto" my="6">
	    <Image mx="auto" src={logo[colorMode]} />
	  </Box>
	  <Flex direction={{ base: 'column', sm: 'column', md: 'row' }} justifyContent={{ base: 'center', sm: 'center', md: 'space-between', lg: 'space-between' }} align="baseline">
	    <Box mx="auto">
	      <Text align="center" my="3" fontSize={{ base: "md", md: "xl"}} color="gray.500">
    &copy; 2020 Mindset Delivery. All Rights Reserved.
	      </Text>
	    </Box>
	    <Stack isInline spacing={4} mx="auto">
	      <Link isExternal href="https://twitter.com/mindsetdelivery">
		<Icon as={FaTwitter}  color={textBgColor[colorMode]} fontSize="xl" _hover={{ color: 'brand.400' }} />
	      </Link>
	      <Link isExternal href="https://facebook.com/mindsetdeliveryr">
		<Icon as={FaFacebook}  color={textBgColor[colorMode]} fontSize="xl" _hover={{ color: 'brand.400' }} />
	      </Link>
            </Stack>
	  </Flex>
	</Stack>
      </Flex>
    </Container>
  )
}

export { Footer }
