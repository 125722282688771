import React from "react";
import {
	Container,
	Box,
	Flex,
	Stack,
	SimpleGrid,
	Image,
	Icon,
	Text,
	useColorMode,
} from "@chakra-ui/react";
import { FaCheck, FaBrain } from "react-icons/fa";

import Laptop from "../assets/laptop.jpg";

const Features = () => {
	const { colorMode } = useColorMode();

	const textBgColor = { light: "brand.400", dark: "brand.200" };
	const iconColor = { light: "brand.100", dark: "brand.400" };
	const titleColor = { light: "gray.600", dark: "gray.300" };

	return (
		<Box>
			<Container maxW={"5xl"} py={12}>
				<Flex
					h={{ base: "auto", md: "100vh", lg: "100vh" }}
					align='center'
					justify='center'
				>
					<SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing={10}>
						<Flex w={{ base: "100%", sm: "100%", md: "50%", lg: "80%" }} mx='auto'>
							<Box boxShadow='lg' rounded='lg' overflow='hidden'>
								<Image src={Laptop} />
							</Box>
						</Flex>
						<Stack spacing={4} px='6'>
							<Flex>
								<Box
									bg={textBgColor[colorMode]}
									d='flex'
									size='sm'
									w='70px'
									h='70px'
									boxShadow='lg'
									rounded='full'
									alignItems='center'
									justifyContent='center'
								>
									<Icon as={FaBrain} color={iconColor[colorMode]} />
								</Box>
							</Flex>
							<Flex direction='column'>
								<Text
									textTransform={"uppercase"}
									fontWeight={600}
									fontSize={{ base: "xl", sm: "xl", md: "2xl", lg: "2xl" }}
									alignSelf={"flex-start"}
									rounded={"md"}
									color={titleColor[colorMode]}
								>
									WHY MINDSET IS IMPORTANT?
								</Text>
								<Text my='3' fontSize={{ base: "md", md: "xl" }} color='gray.500'>
									Your mindset has a huge role in your chance of succeeding in
									life. In this Course we will help you shape a mindset that will
									increase the quality your life and relationships. You will get
								</Text>
								<Stack spacing='4'>
									<Stack isInline spacing='4' align='baseline'>
										<Box
											bg={textBgColor[colorMode]}
											d='flex'
											size='sm'
											w={{ base: "30px", md: "40px" }}
											h={{ base: "30px", md: "40px" }}
											boxShadow='lg'
											rounded='full'
											alignItems='center'
											justifyContent='center'
										>
											<Icon as={FaCheck} color={iconColor[colorMode]} />
										</Box>
										<Text color='gray.500' fontSize={{ base: "md", md: "xl" }}>
											8 Week course
										</Text>
									</Stack>
									<Stack isInline spacing='4' align='baseline'>
										<Box
											bg={textBgColor[colorMode]}
											d='flex'
											size='sm'
											w={{ base: "30px", md: "40px" }}
											h={{ base: "30px", md: "40px" }}
											boxShadow='lg'
											rounded='full'
											alignItems='center'
											justifyContent='center'
										>
											<Icon as={FaCheck} color={iconColor[colorMode]} />
										</Box>
										<Text color='gray.500' fontSize={{ base: "md", md: "xl" }}>
											Community of liked minded people
										</Text>
									</Stack>
									<Stack isInline spacing='4' align='baseline'>
										<Box
											bg={textBgColor[colorMode]}
											d='flex'
											size='sm'
											w={{ base: "30px", md: "40px" }}
											h={{ base: "30px", md: "40px" }}
											boxShadow='lg'
											rounded='full'
											alignItems='center'
											justifyContent='center'
										>
											<Icon as={FaCheck} color={iconColor[colorMode]} />
										</Box>
										<Text color='gray.500' fontSize={{ base: "md", md: "xl" }}>
											A chance to take control of your success
										</Text>
									</Stack>
								</Stack>
							</Flex>
						</Stack>
					</SimpleGrid>
				</Flex>
			</Container>
		</Box>
	);
};

export { Features };
