import React from 'react'
import {
  Box,
  Flex,
  Image,
  Button,
  Container,
  useColorMode,
} from '@chakra-ui/react'
import {
  MoonIcon,
  SunIcon
} from '@chakra-ui/icons'

import LightModeLogo from '../assets/logolightmode.svg'
import DarkModeLogo from '../assets/logodarkmode.svg'

const Nav = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  const Logo = { light: LightModeLogo, dark: DarkModeLogo }
  const bgColor = { light: 'white', dark: 'gray.700' }
  const textColor = { light: 'gray.600', dark: 'gray.200' }

  return (
    <Box bg={bgColor[colorMode]}>
      <Container maxW={'6xl'}>
	<Flex
          color={textColor[colorMode]}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
	  justify='space-between'
	>
          <Flex flex={{ base: 1 }} justify='start' align="center">
	    <Box w="150px">
	      <Image src={Logo[colorMode]} alt="Logo" />
	    </Box>
          </Flex>
	  <Flex justify='start' align="center">
	    <Button
	      colorScheme="brand"
	      aria-label="Color Mode Toggle"
	      size="sm"
	      onClick={toggleColorMode}
	    >
	      {
		colorMode === 'light'
		? (<MoonIcon />)
		: (<SunIcon />)
	      }
	    </Button>
	  </Flex>
	</Flex>
      </Container>
    </Box>
  )
}

export {Nav}
