import { Box, Center, useColorModeValue, Heading, Text, Stack, Image } from "@chakra-ui/react";
import IMAGE from "../assets/events.jpg";

const Events = () => (
	<Center py={12} h={{ base: "auto", md: "100vh", lg: "100vh" }}>
		<Box>
			<Heading
				textTransform={"uppercase"}
				fontWeight={600}
				fontSize={{ base: "xl", sm: "xl", md: "2xl", lg: "2xl" }}
				mb='20'
				align='center'
			>
				WHAT IS HAPPENING...
			</Heading>
			<Box
				role={"group"}
				p={6}
				maxW={"330px"}
				w={"full"}
				bg={useColorModeValue("white", "gray.800")}
				boxShadow={"2xl"}
				rounded={"lg"}
				pos={"relative"}
				zIndex={1}
			>
				<Box
					rounded={"lg"}
					mt={-12}
					pos={"relative"}
					height={"230px"}
					_after={{
						transition: "all .3s ease",
						content: '""',
						w: "full",
						h: "full",
						pos: "absolute",
						top: 5,
						left: 0,
						backgroundImage: `url(${IMAGE})`,
						filter: "blur(15px)",
						zIndex: -1,
					}}
					_groupHover={{
						_after: {
							filter: "blur(20px)",
						},
					}}
				>
					<Image
						rounded={"lg"}
						height={230}
						width={282}
						objectFit={"cover"}
						src={IMAGE}
					/>
				</Box>
				<Stack pt={10} align={"center"}>
					<Text color={"gray.500"} fontSize={"sm"}>
						28. Jan. 2022
					</Text>
					<Text color={"gray.500"} fontSize={"sm"}>
						6:00 pm to 8:00 pm
					</Text>
					<Heading fontSize={"2xl"} fontFamily={"body"} fontWeight={800}>
						Take Action Event!
					</Heading>
					<Stack direction={"row"} align={"center"}>
						<Text fontWeight={500} fontSize={"xl"}>
							at Barkulan
						</Text>
					</Stack>
				</Stack>
			</Box>
		</Box>
	</Center>
);

export { Events };
