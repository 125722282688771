import { extendTheme } from "@chakra-ui/react"


const config = {
  InitialColorMode: 'light',
  useSystemColorMode: false
}

const colors = {
  brand: {
    50: '#ffece6',
    100: '#eccec7',
    200: '#d9b0a5',
    300: '#c89283',
    400: '#b77361',
    500: '#9e5948',
    600: '#7c4637',
    700: '#5a3127',
    800: '#381d15',
    900: '#1b0600',
  },
  brand2: {
    50: '#fff3df',
    100: '#f4ddbc',
    200: '#e8c794',
    300: '#deb06c',
    400: '#d59a44',
    500: '#bb802a',
    600: '#92641f',
    700: '#694715',
    800: '#3f2b08',
    900: '#1b0c00',
  },
  brandButtons: {
    50: '#e1fcf8',
    100: '#c0eee6',
    200: '#9ce1d6',
    300: '#77d4c5',
    400: '#53c8b5',
    500: '#3baf9c',
    600: '#2b8879',
    700: '#1b6156',
    800: '#093b34',
    900: '#001511',
  }

}

const theme = extendTheme({ colors, config })

export default theme
