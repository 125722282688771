import React from "react";
import { Flex, Container, Heading, Stack, Text, Image, Link, Button } from "@chakra-ui/react";

import Illustration from "../assets/Heroimage.png";

const Hero = () => (
	<Flex h={{ base: "auto", md: "100vh", lg: "100vh" }} align='center' justify='center'>
		<Container maxW={"5xl"} mt='5'>
			<Stack
				textAlign={"center"}
				align={"center"}
				spacing={{ base: 8, md: 10 }}
				py={{ base: 20, md: 28 }}
			>
				<Heading
					fontWeight={600}
					fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
					lineHeight={"100%"}
				>
					<Text as={"span"} color='brand.400'>
						MINDSET
					</Text>{" "}
					IS EVERYTHING!
				</Heading>
				<Text color={"gray.500"} fontSize={{ base: "2xl", md: "3xl" }} maxW={"3xl"}>
					Let us help you build the life you want.
				</Text>
				<Stack spacing={6} direction={"row"}>
					<Link href='#cta'>
						<Button
							px={6}
							colorScheme='brand'
							boxShadow='sm'
							_hover={{ bg: "brand.400", boxShadow: "md" }}
						>
							Get started
						</Button>
					</Link>
				</Stack>
				<Flex w={{ base: "100%", sm: "100%", md: "80%", lg: "50%" }}>
					<Image src={Illustration} alt='Mindset Illustration' />
				</Flex>
			</Stack>
		</Container>
	</Flex>
);

export { Hero };
