import React from 'react'
import {
  Box,
  Text,
  Image,
  Flex,
  useColorMode
} from '@chakra-ui/react';

import SubscriptionForm from './subscribeForm'
import ComingSoon from '../assets/comingsoon.png'

const CTA = () => {
  const { colorMode } = useColorMode()

  const titleColor = { light: "gray.600", dark: "gray.300"}

  return (
    <Box id="cta">
      <Flex direction={{base: 'column', md: 'column', lg:'row'}} align="center" justify="center" h={{base: "auto", md: "100vh", lg: "100vh"}}>

	<Box w={{ base: '100%', md: '100%', lg: '40%' }}>
	  <Flex direction="column" p="3">
	    <Text
	      textTransform={'uppercase'}
	      fontWeight={600}
	      fontSize={{ base: 'xl', sm: 'xl', md: '2xl', lg: '2xl'}}
	      align="center"
	      rounded={'md'}
	      color={titleColor[colorMode]}
	    >
	      DON'T MISS OUT THE LAUNCH DAY!
            </Text>
	    <Text align="center" my="3" fontSize={{ base: "md", md: "xl"}} color="gray.500">
	      Subscribe so we can notify you when we launch.
	    </Text>
	  </Flex>
	  <Flex w={{ base: "90%", md: "50%" }} justify="center" mx="auto">
	    <SubscriptionForm />
	  </Flex>
	</Box>

	<Box w={{ base: '80%', md: '80%', lg: '40%' }} my={{ base: "12"}}>
	  <Box w="80%" mx="auto">
	    <Image src={ComingSoon} />
	  </Box>
	</Box>

      </Flex>
    </Box>
  )
}

export { CTA }
