import React from "react";
import Helmet from "react-helmet";
import { Nav, Hero, Features, Events, CTA, Footer } from "./components";

const App = () => (
	<>
		<Helmet>
			<meta
				name='description'
				content='Mindset Program that will help you build the life you want'
			/>
			<meta itemprop='name' content='Mindset Delivery | Mindset is everything' />
			<meta
				itemprop='description'
				content='Mindset Program that will help you build the life you want'
			/>
			<meta
				itemprop='image'
				content='https://mindsetdelivery.com/static/media/Heroimage.558a6e63.png'
			/>

			<meta property='og:url' content='https://mindsetdelivery.com' />
			<meta property='og:type' content='website' />
			<meta property='og:title' content='Mindset Delivery | Mindset is everything' />
			<meta
				property='og:description'
				content='Mindset Program that will help you build the life you want'
			/>
			<meta
				property='og:image'
				content='https://mindsetdelivery.com/static/media/Heroimage.558a6e63.png'
			/>

			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content='Mindset Delivery | Mindset is everything' />
			<meta
				name='twitter:description'
				content='Mindset Program that will help you build the life you want'
			/>
			<meta
				name='twitter:image'
				content='https://mindsetdelivery.com/static/media/Heroimage.558a6e63.png'
			/>
		</Helmet>
		<Nav />
		<Hero />
		<Features />
		<Events />
		<CTA />
		<Footer />
	</>
);

export default App;
