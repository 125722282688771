import React, { useState } from 'react'
import { Button, Input, Alert, AlertIcon, InputGroup, InputLeftElement, FormControl, Stack } from '@chakra-ui/react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { EmailIcon } from '@chakra-ui/icons'
import { FaUserAlt } from 'react-icons/fa'


const SubscriptionForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const url = "//mindsetdelivery.us1.list-manage.com/subscribe/post?u=70e0e7006d948d2ee6f3ffdcf&id=a528f21b5d"

  return (
    <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <form
      onSubmit={(e) => {
	e.preventDefault()
	subscribe({
	  NAME: name,
	  EMAIL: email
	})
      }}
      method="POST"
      >
      <Stack spacing="4">
	<FormControl isRequired>
	  <InputGroup>
	    <InputLeftElement
	      pointerEvents="none"
	      children={<FaUserAlt />}
	    />
	    <Input
	      type="text"
	      name="b_name"
	      value={name}
	      onChange={e => setName(e.target.value)}
	      id="b_name"
	      placeholder="Name"
	    />
	  </InputGroup>
	</FormControl>
	<FormControl isRequired>
	  <InputGroup>
	    <InputLeftElement
	    pointerEvents="none"
	    children={<EmailIcon />}
	    />
	    <Input
	      type="email"
	      name="b_email"
	      id="b_email"
	      placeholder="Email"
	      value={email}
	      onChange={e => setEmail(e.target.value)}
	    />
	  </InputGroup>
	</FormControl>
	<Button
	  type="submit"
	  px={6}
          colorScheme='brand'
	  boxShadow="sm"
          _hover={{ bg: 'brand.400', boxShadow: 'md' }}
      >
      Submit
	</Button>

	{status === "sending" && (
	  <Alert status="info">
	    <AlertIcon />
	    Sending...
	  </Alert>
	)}

	{status === "error" && (
	  <Alert status="error">
	    <AlertIcon />
	    There was an error processing your request.
	  </Alert>
	)}

	{status === "success" && (
	  <Alert status="success">
	    <AlertIcon />
	    Successfully Subscribed. Check your email!
	  </Alert>
	)}
      </Stack>
      </form>
    )}
    />
  )
}

export default SubscriptionForm
